import { notification } from "@pankod/refine-antd";
import { AuthProvider, ILoginForm } from "@pankod/refine-core";
import axios from "axios";
import { UserRole } from "enums";
import { IChangePassForm, ITokenData, IUser } from "interfaces";
import { jwtDecode } from "jwt-decode";
import { IDefinePassForm } from "pages/login";
import { buildErrorNotification } from "utils/errorValidation";

const TOKEN_KEY = "token";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

function setToken(token: string) {
  if (!token) {
    return unsetToken();
  }

  localStorage.setItem(TOKEN_KEY, token);
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return token;
}

function unsetToken() {
  localStorage.removeItem(TOKEN_KEY);
  delete axiosInstance.defaults.headers.common["Authorization"];

  return null;
}

function getToken() {
  const token = localStorage.getItem(TOKEN_KEY);

  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return token;
}

function extractDataFromToken(token: string | null) {
  if (!token) {
    return null;
  }

  const data = jwtDecode<ITokenData>(token);
  return data;
}

const authProvider: AuthProvider = {
  login: async (body: ILoginForm | IDefinePassForm) => {
    try {
      const url =
        "newPassword" in body ? "/auth/define-password" : "/auth/login";

      const { data } = await axiosInstance.post(url, body);
      const { id_token, session } = data;

      if (session) {
        return Promise.resolve(false);
      }

      setToken(id_token);

      return Promise.resolve("/batch/all");
    } catch (error) {
      const notificationParams = buildErrorNotification(
        error,
        "Não foi possível efetuar o login",
      );

      return Promise.reject({
        name: notificationParams.description,
        message: notificationParams.message,
      });
    }
  },

  logout: () => {
    unsetToken();

    notification.info({
      message: "Desconectado. Faça login novamente",
    });

    return Promise.resolve("/login");
  },

  checkError: (error) => {
    const { status, statusCode } = axios.isAxiosError(error)
      ? error.toJSON()
      : error;

    if (status === 401 || statusCode === 401) {
      return authProvider.checkAuth();
    }

    return Promise.resolve();
  },

  checkAuth: () => {
    const token = getToken();
    const tokenData = extractDataFromToken(token);

    if (!tokenData) {
      return Promise.reject();
    }

    const expirationInMs = tokenData.exp * 1000;

    if (Date.now() > expirationInMs) {
      return Promise.reject("/login");
    }

    return Promise.resolve();
  },

  getPermissions: () => Promise.resolve(undefined),

  getUserIdentity: () => {
    const token = getToken();
    const decodedToken = extractDataFromToken(token);

    if (!decodedToken) {
      return Promise.reject();
    }

    const user: IUser = {
      name: decodedToken.name,
      email: decodedToken.email,
      bankId: decodedToken["custom:bankId"],
      role: decodedToken["custom:userRole"] as UserRole,
      createdAt: "-",
      updatedAt: "-",
    };

    return Promise.resolve(user);
  },

  updatePassword: async (changePassForm: IChangePassForm) => {
    const body = {
      email: changePassForm.email,
      password: changePassForm.password,
      newPassword: changePassForm.newPassword,
    };

    try {
      const { data, status } = await axiosInstance.post(
        "/users/change-password",
        body,
      );

      const token = data.id_token;
      if (status !== 201 || !token) {
        return Promise.reject();
      }

      setToken(token);

      notification.success({
        message: "Senha atualizada com sucesso",
      });

      changePassForm.setVisible(false);

      return Promise.resolve();
    } catch (error) {
      const notificationParams = buildErrorNotification(
        error,
        "Não foi possível atualizar a senha",
      );

      notification.error({
        message: notificationParams.message,
        description: notificationParams.description,
      });
    }
  },
};

export { authProvider, axiosInstance };
