import { TextField, Table, useTable } from "@pankod/refine-antd";
import { CrudFilter, HttpError } from "@pankod/refine-core";
import { IPayerDetail } from "interfaces";
import { ReactNode, useState, useEffect } from "react";
import { convertToBRL } from "utils/decimalConverts";
import { buildErrorNotification } from "utils/errorValidation";

interface PayersTableProps {
  batchName: string;
  handleExpandedRow: (payer: IPayerDetail) => ReactNode;
  setTotalDueAmount: React.Dispatch<React.SetStateAction<number>>;
}

const PayersTable: React.FC<PayersTableProps> = ({
  batchName,
  handleExpandedRow,
  setTotalDueAmount,
}: PayersTableProps) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const handleColumnNameRender = (item: string) => {
    if (item === "total") {
      return (
        <TextField
          style={{
            fontWeight: "bold",
          }}
          value={item.toUpperCase()}
        />
      );
    }

    return item.toUpperCase();
  };

  const permanentFilter: CrudFilter[] = [
    {
      field: "batchName",
      operator: "eq",
      value: `${batchName}`,
    },
    {
      field: "status",
      operator: "eq",
      value: "pending",
    },
  ];

  const {
    tableProps,
    tableQueryResult: { data: filterPayers },
  } = useTable<IPayerDetail, HttpError>({
    resource: "payment/payers",

    permanentFilter: permanentFilter,

    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os pagadores!",
      );
    },
  });

  useEffect(() => {
    if (filterPayers?.data) {
      const payers: IPayerDetail[] = filterPayers.data;
      const total: number = payers.reduce(
        (acc, payer) => acc + payer.totalDueAmount,
        0,
      );
      setTotalDueAmount(total);
    }
  }, [filterPayers]);

  const handleExpand = (record: IPayerDetail) => {
    const key = record.payerName;
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key],
    );
  };

  return (
    <Table
      {...tableProps}
      showHeader={false}
      size="small"
      expandable={{
        expandedRowRender: (item) => handleExpandedRow(item),
        expandedRowKeys,
        onExpand: (expanded, record) => handleExpand(record),
      }}
      rowKey={"payerName"}
    >
      <Table.Column
        dataIndex={"payerName"}
        title="Nome"
        render={handleColumnNameRender}
      />
      <Table.Column
        dataIndex={"totalDueAmount"}
        align="right"
        title="Valor"
        render={(value) => (value ? `${convertToBRL(value)}` : "****")}
      />
    </Table>
  );
};

export default PayersTable;
