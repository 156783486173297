import { notification } from "@pankod/refine-antd";
import {
  useApiUrl,
  useCheckError,
  useIsExistAuthentication,
} from "@pankod/refine-core";
import { axiosInstance } from "authProvider";
import { IPayerDetail, IPayment } from "interfaces";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { buildErrorNotification } from "utils/errorValidation";

interface IPaymentState {
  getPaymentsByIds: (paymentIds: number[]) => Promise<IPayment[]>;
  getPayers: (body: {}) => Promise<IPayerDetail[]>;
  deletePayments: (paymentIds: number[]) => Promise<void>;
  updateBalances: (accountNumbers: string[]) => Promise<void>;
}

export const PaymentStateContext = createContext<IPaymentState>(null!);

export function PaymentStateProvider({ children }: { children: ReactNode }) {
  const [getPaymentsByIds, setGetPaymentsByIds] = useState<
    (paymentIds: number[]) => Promise<IPayment[]>
  >(null!);
  const [getPayers, setGetPayers] = useState<
    (body: {}) => Promise<IPayerDetail[]>
  >(null!);
  const [deletePayments, setDeletePayments] = useState<
    (paymentIds: number[]) => Promise<void>
  >(null!);
  const [updateBalances, setUpdateBalances] = useState<
    (accountNumbers: string[]) => Promise<void>
  >(null!);

  const isAuthenticated = useIsExistAuthentication();
  const { mutate: checkError } = useCheckError();
  const apiUrl = useApiUrl();

  useEffect(() => {
    const handleGetPaymentsByIds = async (
      paymentIds: number[],
    ): Promise<IPayment[]> => {
      if (!isAuthenticated || paymentIds.length === 0) {
        return [];
      }

      const idsPath = paymentIds.join(",");

      try {
        const response = await axiosInstance.get<IPayment | IPayment[]>(
          `${apiUrl}/payment/${idsPath}`,
        );

        if (!Array.isArray(response.data)) {
          return [response.data];
        }

        return response.data;
      } catch (error) {
        checkError(error);
        const notificationParams = buildErrorNotification(
          error,
          "Erro ao buscar pagamentos",
        );
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
        return [];
      }
    };

    const handleGetPayers = async (body: {}): Promise<IPayerDetail[]> => {
      if (!isAuthenticated) {
        return [];
      }

      try {
        const response = await axiosInstance.get<IPayerDetail[]>(
          `${apiUrl}/payment/payers`,
          body,
        );

        return response.data;
      } catch (error) {
        checkError(error);
        const notificationParams = buildErrorNotification(
          error,
          `Erro ao buscar pagadores`,
        );
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
        return [];
      }
    };

    const handleDeletePayments = async (paymentIds: number[]) => {
      if (!isAuthenticated || paymentIds.length === 0) {
        return;
      }

      const successMessage =
        paymentIds.length === 1
          ? `Pagamento id: ${paymentIds[0]} excluído com sucesso`
          : "Pagamentos excluídos com sucesso";

      const errorMessage =
        paymentIds.length === 1
          ? `Erro ao excluir pagamento id: ${paymentIds[0]}`
          : "Erro ao excluir pagamentos";

      try {
        await axiosInstance.delete(`${apiUrl}/payment/`, {
          headers: {
            "Content-Type": "application/json",
          },
          data: { ids: paymentIds },
        });
        notification.success({ message: successMessage });
      } catch (error) {
        checkError(error);
        const notificationParams = buildErrorNotification(error, errorMessage);
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
      }
    };

    const handleUpdateBalances = async (
      accountNumbers: string[],
    ): Promise<void> => {
      if (!isAuthenticated) {
        return;
      }

      try {
        await axiosInstance.post(`${apiUrl}/payment/updatebalance`, {
          accountNumbers: accountNumbers,
        });

        notification.success({
          message: `Tabela atualizada com sucesso!`,
        });
      } catch (error) {
        checkError(error);
        const notificationParams = buildErrorNotification(
          error,
          `Erro ao atualizar tabela`,
        );
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
      }
    };

    setGetPaymentsByIds(() => handleGetPaymentsByIds);
    setDeletePayments(() => handleDeletePayments);
    setUpdateBalances(() => handleUpdateBalances);
    setGetPayers(() => handleGetPayers);
  }, [isAuthenticated]);

  const value = useMemo(
    () => ({
      deletePayments,
      getPaymentsByIds,
      getPayers,
      updateBalances,
    }),
    [deletePayments, getPaymentsByIds, getPayers, updateBalances],
  );

  return (
    <PaymentStateContext.Provider value={value}>
      {children}
    </PaymentStateContext.Provider>
  );
}
