import { BankStateContext } from "context/bank";
import { BatchStateContext } from "context/batch";
import { PaymentStateContext } from "context/payment";
import { useContext } from "react";

export const usePayment = () => {
  return useContext(PaymentStateContext);
};

export const useBatch = () => {
  return useContext(BatchStateContext);
};

export const useBank = () => {
  return useContext(BankStateContext);
};
