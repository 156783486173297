import {
  Popover,
  Button,
  RefreshButton,
  DeleteButton,
} from "@pankod/refine-antd";
import React, { useEffect, useState } from "react";
import {
  CloseCircleOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { IBatch, IPayment, IUser } from "interfaces";
import { StatusType, UserRole } from "enums";
import {
  OpenNotificationParams,
  useGetIdentity,
  useNavigation,
} from "@pankod/refine-core";
import { useBatch, usePayment } from "hooks";
import Voucher from "components/voucherTemplate";
import { buildErrorNotification } from "utils/errorValidation";
import BatchSummaryModal from "../summary";

interface BatchActionsProps {
  batch: IBatch;
  refetchTable: () => Promise<void>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isApprovePage?: boolean;
  style?: React.CSSProperties;
}

export const BatchActions: React.FC<BatchActionsProps> = ({
  batch,
  refetchTable,
  isLoading,
  setIsLoading,
  isApprovePage,
  style,
}) => {
  const [canApprovePayment, setCanApprovePayment] = useState<boolean>(false);
  const [canDeletePayment, setCanDeletePayment] = useState<boolean>(false);
  const [onlyView, setOnlyView] = useState<boolean>(false);

  const { data: user } = useGetIdentity<IUser>();

  const { push } = useNavigation();

  const { updateBalances } = usePayment();

  const { getPaymentsByStatus } = useBatch();

  useEffect(() => {
    if (user) {
      const userRole = user?.role as UserRole;
      setCanApprovePayment(
        [UserRole.ADMIN, UserRole.MANAGER, UserRole.APPROVER].includes(
          userRole,
        ),
      );
      setCanDeletePayment(
        [
          UserRole.ADMIN,
          UserRole.MANAGER,
          UserRole.APPROVER,
          UserRole.OPERATOR,
        ].includes(userRole),
      );
      setOnlyView(
        ![
          UserRole.ADMIN,
          UserRole.MANAGER,
          UserRole.APPROVER,
          UserRole.OPERATOR,
        ].includes(userRole),
      );
    }
  }, [user]);

  const validStatusesToDeletePayment = new Set([
    StatusType.PENDING,
    StatusType.UNAVAILABLE,
  ]);

  const checkStatuses = (
    statuses: StatusType[],
    validStatuses: Set<StatusType>,
  ): boolean => {
    return statuses.some((status) => validStatuses.has(status));
  };

  const paymentCanBeDeleted = checkStatuses(
    batch.status as StatusType[],
    validStatusesToDeletePayment,
  );

  const ApprovePageOptions = () => {
    const havePaymentsToPay: boolean = batch.totalByStatus.pending > 0;

    const canShowSummary = canApprovePayment && havePaymentsToPay;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {canShowSummary && (
          <BatchSummaryModal
            isBatchPageButton
            disabled={isLoading}
            data={batch}
            onStart={async () => {
              setIsLoading(true);
            }}
            onFinish={async () => {
              await refetchTable();
              setIsLoading(false);
            }}
          />
        )}

        <DefaultPageOptions />
      </div>
    );
  };

  const DefaultPageOptions = () => {
    const successNotification: OpenNotificationParams = {
      message: `Pagamentos pendentes/indisponíveis do lote ${batch.batchName} foram excluídos com sucesso!`,
      type: "success",
    };

    const errorNotification = (error: any) => {
      return buildErrorNotification(
        error,
        `Não foi possível excluir os pagamentos pendentes/indisponíveis do lote ${batch.batchName}!`,
      );
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {paymentCanBeDeleted && canDeletePayment && (
          <DeleteButton
            recordItemId={batch.batchName}
            resourceNameOrRouteName="batch"
            type="text"
            disabled={isLoading}
            icon={<CloseCircleOutlined />}
            confirmTitle="Você tem certeza?"
            confirmCancelText="Cancelar"
            confirmOkText="Excluir"
            successNotification={successNotification}
            errorNotification={errorNotification}
          >
            Excluir Pendentes/Indisponíveis
          </DeleteButton>
        )}
        <ShowPayments />
      </div>
    );
  };

  const ShowPayments = () => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const path = window.location.pathname;

    const canUpdateBalance = batch.accountNumbersToUpdate.length > 0;

    const update = async (): Promise<void> => {
      setIsLoading(true);
      setIsUpdating(true);
      await updateBalances(batch.accountNumbersToUpdate);
      await refetchTable();
    };

    const gettingDataForVoucher = async (): Promise<IPayment[]> => {
      setIsLoading(true);
      return await getPaymentsByStatus(batch, StatusType.PAID);
    };

    const navigatorClick = () => {
      if (batch.batchName) {
        const param = batch.batchName.replace(/ /g, "%20");
        push(`${path}/${param}`);
      } else {
        push(`${path}/null`);
      }
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {canUpdateBalance && (
          <RefreshButton
            type="text"
            disabled={isLoading}
            loading={isUpdating}
            onClick={() =>
              update().then(() => {
                setIsLoading(false);
                setIsUpdating(false);
              })
            }
          >
            Atualizar
          </RefreshButton>
        )}
        {batch.totalByStatus.paid > 0 && (
          <Voucher
            isBatchPageButton
            data={gettingDataForVoucher}
            disabled={isLoading}
            onDowloadFinish={async () => setIsLoading(false)}
          />
        )}
        <Button
          type="text"
          disabled={isLoading}
          icon={<EyeOutlined />}
          onClick={navigatorClick}
        >
          Visualizar
        </Button>
      </div>
    );
  };

  const RenderedComponent = () => {
    let content: React.ReactNode;

    if (onlyView) {
      content = <ShowPayments />;
    } else if (isApprovePage) {
      content = <ApprovePageOptions />;
    } else {
      content = <DefaultPageOptions />;
    }

    return (
      <Popover content={content}>
        <Button type="text">
          <EllipsisOutlined style={style ?? { fontSize: "32px" }} />
        </Button>
      </Popover>
    );
  };

  return <RenderedComponent />;
};
