import React, { useEffect, useState } from "react";
import { IBatch, IPayerDetail, IUser } from "interfaces";
import { CheckCircleOutlined, DollarOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  useModal,
  Spin,
  Typography,
  Col,
  Row,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { convertToBRL } from "utils/decimalConverts";
import { useBatch } from "hooks";
import PayersTable from "./payersTable";
import PaymentsForPayTable from "./paymentsForPayTable";
import { UserRole } from "enums";

interface BatchSummaryModalProps {
  isBatchPageButton?: boolean;
  disabled?: boolean;
  data?: IBatch;
  onStart?: () => Promise<void>;
  onFinish?: () => Promise<void>;
}

const BatchSummaryModal: React.FC<BatchSummaryModalProps> = ({
  isBatchPageButton,
  disabled,
  data,
  onStart,
  onFinish,
}: BatchSummaryModalProps) => {
  const { modalProps, show } = useModal({ modalProps: {} });
  const [hiddenBtnPayment, setHiddenBtnPayment] = useState<boolean>(true);
  const [canApprovePayment, setCanApprovePayment] = useState<boolean>(false);
  const [payManyIsLoading, setPayManyIsLoading] = useState<boolean>(false);
  const [totalDueAmount, setTotalDueAmount] = useState<number>(0);

  const { data: user } = useGetIdentity<IUser>();

  const { approveBatch } = useBatch();

  useEffect(() => {
    if (user) {
      const userRole = user?.role as UserRole;
      setCanApprovePayment(
        [UserRole.ADMIN, UserRole.MANAGER, UserRole.APPROVER].includes(
          userRole,
        ),
      );
    }

    setHiddenBtnPayment(!canApprovePayment);
  }, [canApprovePayment, data, user]);

  const paySelectedItems = async () => {
    if (onStart) await onStart();

    if (!canApprovePayment) {
      if (onFinish) await onFinish();
      return;
    }

    if (data) {
      setPayManyIsLoading(true);
      await approveBatch(data.batchName);
      if (onFinish) await onFinish();
      setPayManyIsLoading(false);
    }
  };

  const CustomButton = () => {
    return (
      <Button
        type={isBatchPageButton ? "text" : undefined}
        disabled={disabled}
        icon={isBatchPageButton ? <CheckCircleOutlined /> : <DollarOutlined />}
        style={
          isBatchPageButton && !disabled ? { color: "#0A9952" } : undefined
        }
        onClick={show}
      >
        {isBatchPageButton ? "Aprovar Pendentes" : "Pagamentos"}
      </Button>
    );
  };

  const handleExpandedRow = (payer: IPayerDetail): JSX.Element => {
    if (!data) {
      return <Spin size={"large"} />;
    }

    return <PaymentsForPayTable payer={payer} batchName={data.batchName} />;
  };

  const LoadTable = (): JSX.Element => {
    if (!data) {
      return <Spin size={"large"} />;
    }

    return (
      <PayersTable
        batchName={data.batchName}
        handleExpandedRow={handleExpandedRow}
        setTotalDueAmount={setTotalDueAmount}
      />
    );
  };

  return (
    <>
      <CustomButton />
      <Modal
        {...modalProps}
        width={800}
        title={`Resumo de pagamentos do lote ${data?.batchName}`}
        onOk={paySelectedItems}
        okText={payManyIsLoading ? "Confirmando..." : "Confirmar pagamento"}
        okButtonProps={{
          loading: payManyIsLoading,
          hidden: hiddenBtnPayment,
        }}
        cancelButtonProps={{ hidden: payManyIsLoading }}
      >
        <Row justify="end" style={{ marginBottom: "16px" }}>
          <Col>
            <Typography.Title level={5}>
              {`Total: ${convertToBRL(totalDueAmount)}`}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ maxHeight: "400px", overflowY: "auto" }}>
            <LoadTable />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BatchSummaryModal;
