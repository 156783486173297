import { Table, useTable } from "@pankod/refine-antd";
import { CrudFilter, HttpError } from "@pankod/refine-core";
import { IPayerDetail, IPayment } from "interfaces";
import { convertToBRL } from "utils/decimalConverts";
import { buildErrorNotification } from "utils/errorValidation";
import { handleFiscalId } from "utils/tableFunctions";

interface PaymentsForPayTableProps {
  payer: IPayerDetail;
  batchName: string;
}

const PaymentsForPayTable: React.FC<PaymentsForPayTableProps> = ({
  payer,
  batchName,
}: PaymentsForPayTableProps) => {
  const permanentFilter: CrudFilter[] = [
    {
      field: "batchName",
      operator: "eq",
      value: batchName,
    },
    {
      field: "payerName",
      operator: "eq",
      value: `${payer.payerName}`,
    },
    {
      field: "status",
      operator: "eq",
      value: "pending",
    },
  ];

  permanentFilter.push();

  const { tableProps } = useTable<IPayment, HttpError>({
    resource: "payment",
    permanentFilter: permanentFilter,

    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os pagamentos!",
      );
    },
  });

  return (
    <Table
      {...tableProps}
      size="small"
      bordered={false}
      pagination={{
        ...tableProps.pagination,
        totalBoundaryShowSizeChanger: 10,
      }}
    >
      <Table.Column
        dataIndex={"receiverName"}
        title={"Nome destinario"}
        render={(value: string) => value.toUpperCase()}
      />
      <Table.Column
        dataIndex={"receiverFiscalId"}
        title={"CPF/CNPJ destino"}
        render={handleFiscalId}
      />
      <Table.Column
        dataIndex={"dueAmount"}
        title={"Valor"}
        render={(value) => (value ? `${convertToBRL(value)}` : "****")}
      />
    </Table>
  );
};

export default PaymentsForPayTable;
