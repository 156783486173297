import { notification } from "@pankod/refine-antd";
import {
  useApiUrl,
  useCheckError,
  useIsExistAuthentication,
} from "@pankod/refine-core";
import { axiosInstance } from "authProvider";
import { StatusType } from "enums";
import { IBatch, IPayment, IPaymentApproval } from "interfaces";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { buildErrorNotification } from "utils/errorValidation";

interface IBatchState {
  getPaymentsByStatus: (
    batch: IBatch,
    status: StatusType,
  ) => Promise<IPayment[]>;
  approveBatch: (batchName: string) => Promise<void>;
}

export const BatchStateContext = createContext<IBatchState>(null!);

export function BatchStateProvider({ children }: { children: ReactNode }) {
  const [getPaymentsByStatus, setGetPaymentsByStatus] = useState<
    (batch: IBatch, status: StatusType) => Promise<IPayment[]>
  >(null!);
  const [approveBatch, setApprovebatch] = useState<
    (batchName: string) => Promise<void>
  >(null!);

  const isAuthenticated = useIsExistAuthentication();
  const { mutate: checkError } = useCheckError();
  const apiUrl = useApiUrl();

  useEffect(() => {
    const handleGetPaymentsByStatus = async (
      batch: IBatch,
      status: StatusType,
    ): Promise<IPayment[]> => {
      if (!isAuthenticated || !batch.status.includes(status)) {
        return [];
      }

      const getData = async (_start: number, _end: number) => {
        const response = await axiosInstance.get<IPayment[]>(
          `${apiUrl}/payment`,
          {
            params: { status, _start, _end, batchName: batch.batchName },
          },
        );

        return response.data;
      };

      try {
        let results: IPayment[] = [];

        const totalPaymentsForStatus = batch.totalByStatus[status];

        for (let start = 0; start < totalPaymentsForStatus; start += 100) {
          let payments: IPayment[] = [];
          if (start + 99 >= totalPaymentsForStatus) {
            payments = await getData(start, totalPaymentsForStatus - 1);
          } else {
            payments = await getData(start, start + 99);
          }

          results = [...results, ...payments];
        }

        return results;
      } catch (error) {
        checkError(error);
        const notificationParams = buildErrorNotification(
          error,
          "Erro ao buscar pagamentos",
        );
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
        return [];
      }
    };

    const handleApproveBatch = async (batchName: string) => {
      if (!isAuthenticated) {
        return;
      }

      try {
        const response = await axiosInstance.post<IPaymentApproval>(
          `${apiUrl}/batch/approve/${encodeURIComponent(batchName)}`,
        );

        const { successes, failures } = response.data;

        if (successes && successes.length > 0) {
          notification.success({
            message: `Processando pagamentos do lote: ${batchName}`,
            description: `ids: ${successes}`,
          });
        }

        if (failures && failures.length > 0) {
          notification.error({
            message: `Erro ao processar pagamentos do lote: ${batchName}`,
            description: `ids: ${failures}`,
          });
        }
      } catch (error) {
        checkError(error);
        const notificationParams = buildErrorNotification(
          error,
          `Erro ao aprovar pagamentos do lote: ${batchName}`,
        );
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
      }
    };

    setGetPaymentsByStatus(() => handleGetPaymentsByStatus);
    setApprovebatch(() => handleApproveBatch);
  }, [isAuthenticated]);

  const value = useMemo(
    () => ({ getPaymentsByStatus, approveBatch }),
    [getPaymentsByStatus, approveBatch],
  );

  return (
    <BatchStateContext.Provider value={value}>
      {children}
    </BatchStateContext.Provider>
  );
}
